

@media only screen and (min-width: 1200px) {
    .vd1 {
        width: 425px;
        margin-left: 85px;
    }
}

@media only screen and (min-width: 1100px) {
    .vd1 {
        width: 425px;
        margin-left: 5px;
    }
}


@media only screen and (min-width: 992px) {
    .vd1 {
        width: 425px;
        margin-left: 10px;
    }
}


@media only screen and (min-width: 1300px) {
    .vd1 {
        width: 425px;
        margin-left: 150px;
    }
}