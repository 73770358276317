
.wrap{
  max-width: 1210px;
  margin: 50px auto;
  padding: 0 20px;
  position: relative;
}
.wrap .center-line{
  position: absolute;
  height: 100%;
  width: 4px;
  background: black;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
}
.wrap .row{
  display: flex;
}
.wrap .row-1{
  justify-content: flex-start;
}
.wrap .row-2{
  justify-content: flex-end;
}
.wrap .row section{
  background: #141C57;
  border-radius: 20px;
  width: calc(50% - 40px);
  padding: 15px;
  position: relative;
  box-shadow: 2px 2px 7px 0 #00000057;

}
.wrap .row section::before{
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: #2764FF;
  top: 28px;
  z-index: -1;
  transform: rotate(45deg);
}
.row-1 section::before{
  right: -7px;
}
.row-2 section::before{
  left: -7px;
}
.row section .icon,
.center-line .scroll-icon{
  position: absolute;
  background: #f1f1f1;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: black;
  font-size: 20px;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0,0,0,0.08), 0 3px 0 4px rgba(0,0,0,0.05);
}
.center-line .scroll-icon{
  bottom: 0px;
  left: 50%;
  font-size: 15px;
  transform: translateX(-50%);
}
.row-1 section .icon{
  top: 15px;
  right: -60px;
}
.row-2 section .icon{
  top: 15px;
  left: -60px;
}


.row section .details,
.row section .bottom{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.row section .details .title{
  font-size: 19px;
  font-weight: 600;
}
.row section p{
  margin: 10px 0 17px 0;
}

@media(max-width: 790px){
  .wrap .center-line{
    left: 40px;
  }
  .wrap .row{
    margin: 30px 0 3px 60px;
  }
  .wrap .row section{
    width: 100%;
  }
  .row-1 section::before{
    left: -7px;
  }
  .row-1 section .icon{
    left: -60px;
  }
}
@media(max-width: 440px){
  .wrap .center-line,
  .row section::before,
  .row section .icon{
    /* display: none; */
  }
  .wrap .row{
    margin: 50px 0;
  }

  .wrap .center-line{
    left: 40px;
  }
  .wrap .row{
    margin: 40px 0 10px 60px;
  }
  .wrap .row section{
    width: 100%;
  }
  .row-1 section::before{
    left: -6px;
  }
  .row-1 section .icon{
    left: -60px;
  }
}